<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Lenz Media
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text-center text--primary mb-2">
            Welcome to Lenz Media! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <validation-observer ref="observer">
            <v-form>
              <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                <v-text-field
                  v-model="login.email"
                  :error-messages="errors"
                  required
                  outlined
                  label="Email"
                  placeholder="john@example.com"
                  class="mb-3"
                ></v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="password" rules="required">
                <v-text-field
                  v-model="login.password"
                  :error-messages="errors"
                  required
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </validation-provider>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <v-checkbox label="Remember Me" v-model="login.remember" hide-details class="me-3 mt-1"> </v-checkbox>

                <!-- forgot link -->
                <router-link :to="{ name: 'auth-forgot' }" class="d-flex align-center">
                  Forgot Password?
                </router-link>
              </div>

              <v-btn block color="primary" class="mt-6" @click="signin">
                Login
              </v-btn>
            </v-form>
          </validation-observer>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{ name: 'pages-register' }">
            Create an account
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import util from '@/core/utils/misc';

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('email', {
  ...email,
  message: 'The {_field_} must be a working email address',
});

export default {
  name: 'AuthLogin',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      login: {},
      isPasswordVisible: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
  methods: {
    async signin() {
      if (this.$refs.observer.validate()) {
        if (this.$refs.observer.validate()) {
          util.isLoading({ active: true, title: 'Wait while we sign you in.' });
          await this.$store
            .dispatch(`${this.$IYA.NAMESPACE.AUTH}/${this.$IYA.ACTION.LOGIN}`, this.login)
            .then(response => {
              console.log(response);
              if (response.status === this.$IYA.HTTP.OK) {
                if (response.data.user.email_verified_at) {
                  util.setToken(response.data.token);
                  this.$router.push({ name: 'dashboard' });
                } else {
                  this.$router.push({ name: 'Verify-Email' });
                }
              } else if (response.status === this.$IYA.HTTP.HTTP_FAILED_DEPENDENCY) {
                this.$router.push({ name: 'Verify-Email' });
              } else {
                util.errorOccurred({ snackbar: true, message: response.data.message });
              }
            });
          util.isLoading();
        }
      } else {
        console.log('Invalid');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
